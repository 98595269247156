<template>
  <div>
    <slot :downloadFile="downloadFile" />
  </div>
</template>

<script>
import navigation from '@/navigationController'

export default {
  components: {
  },
  methods: {
    async downloadFile (file, params) {
      navigation.downloadFile(file, params)
    }
  }
}
</script>
